<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">追加資料管理 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flex-wrap">
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                @input="setFilter('name', $event)"
                :value="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                :value="search.year"
                @input="setFilter('year', $event)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">すべて</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                @input="setFilter('code', $event)"
                :value="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppAttTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                v-if="row.item.status !== null"
                @change="updateSelectedAppIdList($event, row.item.id)"
                :checked="selectedAppIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/applications/attachment/edit/'+row.item.id">
              編集
            </b-link>
          </template>
        </b-table>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import CONST_OPTIONS from '@/constants/options';
import download from '@/modules/download';

export default {
  name: 'cmsApplicationsAttachmentList',
  data() {
    return {
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_REP_STATUS_OPTION_4_LIST,
      dbAppList: [],
      header: [
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'year', label: '年度', sortable: true },
        { key: 'apptype', label: '助成種別', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      seriesList: [],
      yearList: [],
      isInit: true,
      oldPage: 1,
    };
  },
  methods: {
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsApplicationSearch/setFilter', param);
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const apptypeSort = search.series === '' || lineData.seriesId === search.series;
      // const yearSort = search.year === '' || lineData.year === search.year;
      const codeSort = search.code === '' || lineData.code.includes(search.code);
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      const okInstitution = search.institution_name === '' || lineData.institutionName.includes(search.institution_name);
      const okDivision = search.division === '' || lineData.division === search.division;
      const okKeyword = search.keyword === '' || lineData.keyword.includes(search.keyword);
      return nameSort && apptypeSort && codeSort
        && statusSort && okInstitution && okDivision && okKeyword;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', filteredItems);
    },
    async initFetch(year) {
      const param = { year };
      const promiseFuncs = [api.send('/api/cms/attachments/list', param)];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = responses[0].data.list;
      this.seriesList = responses[0].data.seriesList;
      this.yearList = responses[0].data.yearList;
      // 年度が選択されていなければ、最新の年度を選択状態にする
      if (!this.search.year && this.yearList.length > 0) {
        const yearParam = { key: 'year', value: this.yearList[0] };
        this.$store.commit('cmsApplicationSearch/setFilter', yearParam);
      }
      this.$set(this.search, 'year', responses[0].data.selectedYear);
    },
    setSeries(apptypeId) {
      const target = this.seriesList.find((series) => {
        return series.apptypeList.find((apptype) => {
          return apptype.id === Number(apptypeId);
        });
      });
      if (target) {
        this.$set(this.search, 'series', target.id);
      }
    },
    async control(status) {
      const count = this.selectedAppIdList.length;
      let msg = `選択中の${count}件の追加資料のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、申請者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/attachments/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        return;
      }
      await this.alert('選択した追加資料を受領しました。', false);
      await this.initFetch(this.search.year);
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.$store.commit('cmsApplicationSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/removeSelectedAppid', appId);
      });
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const series = this.seriesList.find((data) => {
        return data.id === this.search.series;
      });
      const fileName = `${ymd}_${series.name}.csv`;
      download.csvUtf(response.data, fileName);
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/pdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const series = this.seriesList.find((data) => {
        return data.id === this.search.series;
      });
      const fileName = `${ymd}_${series.name}.pdf`;
      download.blob(response.data, fileName);
    },
    updateSelectedAppIdList(event, appId) {
      if (!event) {
        this.$store.commit('cmsApplicationSearch/removeSelectedAppid', appId);
      } else {
        this.$store.commit('cmsApplicationSearch/addSelectedAppid', appId);
      }
    },
    setCurrentPage(page) {
      this.$store.commit('cmsApplicationSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsApplicationSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', value);
    },
  },
  computed: {
    search() {
      return this.$store.state.cmsApplicationSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsApplicationSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsApplicationSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsApplicationSearch.totalRows;
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        return {
          id: application.id,
          apptypeId: application.application_type_id,
          apptype: application.apptype_name,
          name: `${application.user_info.sei} ${application.user_info.mei}`,
          kana: `${application.user_info.kana_sei} ${application.user_info.kana_mei}`,
          year: application.apptype_year,
          code: application.code,
          status: application.status,
          statusText: application.status === null ? '未提出' : CONST_STATUS.APP_STATUS_TEXTS[application.status],
          seriesId: application.series_id,
        };
      });
      return appList;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    apptypeList() {
      const appTypeList = this.seriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
      return appTypeList;
    },
    selectedAppIdList() {
      return this.$store.state.cmsApplicationSearch.selectedAppIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    canExportCsv() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
    canExportPdf() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
    divisionOptions() {
      return CONST_OPTIONS.DIVISIONS;
    },
  },
  watch: {
    'search.year': async function (val) {
      this.$store.dispatch('page/onWaiting');
      await this.initFetch(val);
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('userInfo/initInputData');
    this.oldPage = this.currentPage;
    this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    this.$store.commit('cmsApplicationSearch/initFilter');
    const { year, apptypeId } = this.$route.params;
    // ルーターのparamがなければ検索条件のyearを使う
    const fetchYear = year || this.search.year;
    await this.initFetch(fetchYear);
    this.setSeries(apptypeId);
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsAppAttTable thead th:nth-of-type(1) {
    width: 400px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    /*width: 205px;*/
    width: 400px;
  }

  .searchShort {
    /*width: 135px;*/
    width: 215px;
  }

  .searchSpace {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
